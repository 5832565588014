import React from 'react'
import { graphql } from 'gatsby'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import Container from '@material-ui/core/Container'
import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import Comparador from '../../components/Comparador'
import ErrorBoundary from '../../components/ErrorBoundary'

import * as S from './styles'

const ComparadorPage = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <SEO
        title={t('comparador_title_seo')}
        description={t('description_seo')}
      />
      <S.Bg>
        <Container>
          <S.PageTitle>
            <strong>
              <Trans>Está em dúvida sobre o seu tênis ideal?</Trans>
            </strong>
            <br />
            <Trans>Faça um comparativo entre os seus favoritos.</Trans>
          </S.PageTitle>
          <ErrorBoundary>
            <Comparador />
          </ErrorBoundary>
        </Container>
      </S.Bg>
    </Layout>
  )
}

export default ComparadorPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
